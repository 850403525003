var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"indicators-list mt-3 w-100 w-100"},[_c('section',{staticClass:"d-md-flex"},_vm._l((_vm.layoutsMenuLeft),function(layout,index){return _c('div',{key:index,staticClass:"w-100 mx-md-1"},[_c('strong',{staticClass:"ml-2"},[_vm._v(_vm._s(layout.label))]),_c('b-card',{staticClass:"apparence-small layout",class:[
          _vm.is_dark && 'dark',
          layout.apparence,
          _vm.selectedLayout === layout.apparence ? 'active' : '',
          _vm.selectedLayout === layout ? 'active' : '',
        ],attrs:{"no-body":""},on:{"click":function($event){return _vm.handleSelectLayout(layout.apparence)}}},[_c('div',{staticClass:"nav-small"},[_c('img',{staticClass:"mt-1 ml-1",attrs:{"src":require("../../../assets/logo-full.png"),"alt":"","height":"15"}})]),_c('div',{staticClass:"d-md-flex"},[_c('div',{staticClass:"side-small"}),_c('div',{staticClass:"content-small"},[_c('span'),_c('span'),_c('span'),_c('span')])])])],1)}),0),_c('section',{staticClass:"d-md-flex mt-4 menu-top"},_vm._l((_vm.layoutsMenuTop),function(layout,index){return _c('div',{key:index,staticClass:"w-100 mx-md-1"},[_c('strong',{staticClass:"ml-2"},[_vm._v(_vm._s(layout.label))]),_c('b-card',{staticClass:"apparence-small layout",class:[
          _vm.is_dark && 'dark',
          layout.apparence,
          _vm.selectedLayout === layout.apparence ? 'active' : '',
          _vm.selectedLayout === layout ? 'active' : '',
        ],attrs:{"no-body":""},on:{"click":function($event){return _vm.handleSelectLayout(layout.apparence)}}},[_c('div',{staticClass:"nav-small"},[_c('img',{staticClass:"mt-2 ml-1",attrs:{"src":require("../../../assets/logo-full.png"),"alt":"","height":"15"}})]),_c('div',{staticClass:"side-small"}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"content-small"},[_c('span'),_c('span'),_c('span'),_c('span')])])])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }