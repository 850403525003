<template>
  <div class="indicators-list mt-3 w-100 d-md-flex w-100">
    <div v-for="(theme, index) in themes" :key="index" class="w-100 mx-md-1">
      <strong class="ml-2">{{ theme.label }}</strong>
      <b-card
        class="apparence-small shadow theme"
        :class="[
          theme.apparence,
          selectedTheme === theme.apparence ? 'active' : '',
          selectedTheme === theme ? 'active' : '',
        ]"
        no-body
        @click="handleSelectTheme(theme.apparence)"
      >
        <div class="nav-small">
          <img
            src="../../../assets/logo-full.png"
            alt=""
            height="15"
            class="mt-1 ml-1"
          />
        </div>
        <div class="d-flex flex-md-row flex-column-reverse">
          <div class="side-small"></div>
          <div class="content-small">
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import themeProvider from "@/services/apparence/theme";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";

export default {
  data() {
    return {
      selectedTheme: null,
      themes: [
        {
          label: "Claro",
          apparence: "light",
        },
        {
          label: "Escuro",
          apparence: "dark",
        },
      ],
    };
  },
  methods: {
    async handleSelectTheme(theme) {
      this.selectedTheme = theme;
      themeProvider(theme);
      if (Capacitor.isNativePlatform()) {
        await StatusBar.setStyle({ style: Style.Dark });
        if (theme === "dark") {
          return await StatusBar.setBackgroundColor({ color: "#1e1e1e" });
        }
        return await StatusBar.setBackgroundColor({ color: "#347cff" });
      }
    },
  },
  async mounted() {
    this.selectedTheme = localStorage.getItem("theme");
    this.handleSelectTheme(this.selectedTheme);
  },
};
</script>
<style lang="scss">
.apparence-small {
  &.theme {
    &.dark {
      background: #36393f !important;
      .side-small {
        background: #202225;
      }
      .content-small {
        padding: 8px;
        span {
          background: #202225;
        }
      }
      .nav-small {
        background: #202225;
      }
    }
    &.light {
      background: #f1f5f9;
      .side-small {
        background: #fff;
      }
      .content-small {
        background: #f1f5f9;
        padding: 8px;
        span {
          background: #fff;
        }
      }
      .nav-small {
        background: #fff;
      }
    }
  }
}
</style>
