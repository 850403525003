<template>
  <div class="d-md-flex w-100">
    <context-menu ref="context" hide_button>
      <b-button
        block
        :variant="selectedMenu.label === menu.label ? 'primary' : 'light'"
        v-for="(menu, index) in menus"
        :key="index"
        @click="handleSelectMenu(menu)"
        class="text-center d-flex align-items-center px-2"
      >
        <strong class="ml-2">{{ menu.label }}</strong>
      </b-button>
    </context-menu>
    <div class="sysyem-apparence w-100">
      <div class="d-flex align-items-center justify-content-between">
        <h5>{{ selectedMenu.label }}</h5>
      </div>
      <Theme v-if="selectedMenu.menu === 'theme'" />
      <Layout v-if="selectedMenu.menu === 'layout'" />
    </div>
  </div>
</template>
<script>
import Theme from "./theme";
import Layout from "./layout";
import { mapGetters } from "vuex";

export default {
  components: {
    Theme,
    Layout,
  },
  computed: {
    ...mapGetters(["is_mobile"]),
  },
  data() {
    return {
      selectedMenu: "",
      menus: [
        {
          label: "Layout",
          menu: "layout",
        },
        {
          label: "Tema",
          menu: "theme",
        },
      ],
    };
  },
  methods: {
    handleSelectMenu(mdl) {
      this.selectedMenu = mdl;
      this.$refs.context.close();
    },
  },
  mounted() {
    if (!this.is_mobile) {
      this.selectedMenu = this.menus[0];
    } else {
      this.selectedMenu = this.menus[1];
    }
  },
};
</script>
<style lang="scss">
.sysyem-apparence {
  max-width: 920px;
  margin: 0 auto;
}
.apparence-small {
  height: 300px;
  width: 100%;
  max-width: 460px;
  margin: 4px;
  overflow: hidden;
  &.active {
    border-color: $primary;
  }
  cursor: pointer;
  .nav-small {
    height: 30px;
    width: 100%;
    background: $white;
  }
  .content-small {
    width: 100%;
    span {
      display: block;
      height: 10px;
      width: 100%;
      margin-bottom: 1em;
      background: $bg-1;
    }
  }
  .d-flex {
    height: calc(100% - 30px);
  }
  .side-small {
    height: 100%;
    width: 60px;
    background: $bg-1;
    @media (max-width: $break-md) {
      width: 100%;
      height: 40px;
    }
  }
  &.light {
    .d-flex {
      background: #fff !important;
    }
  }
}
</style>
