<template>
  <div class="indicators-list mt-3 w-100 w-100">
    <section class="d-md-flex">
      <div
        v-for="(layout, index) in layoutsMenuLeft"
        :key="index"
        class="w-100 mx-md-1"
      >
        <strong class="ml-2">{{ layout.label }}</strong>
        <b-card
          no-body
          class="apparence-small layout"
          :class="[
            is_dark && 'dark',
            layout.apparence,
            selectedLayout === layout.apparence ? 'active' : '',
            selectedLayout === layout ? 'active' : '',
          ]"
          @click="handleSelectLayout(layout.apparence)"
        >
          <div class="nav-small">
            <img
              src="../../../assets/logo-full.png"
              alt=""
              height="15"
              class="mt-1 ml-1"
            />
          </div>
          <div class="d-md-flex">
            <div class="side-small"></div>
            <div class="content-small">
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
        </b-card>
      </div>
    </section>
    <section class="d-md-flex mt-4 menu-top">
      <div
        v-for="(layout, index) in layoutsMenuTop"
        :key="index"
        class="w-100 mx-md-1"
      >
        <strong class="ml-2">{{ layout.label }}</strong>
        <b-card
          no-body
          class="apparence-small layout"
          :class="[
            is_dark && 'dark',
            layout.apparence,
            selectedLayout === layout.apparence ? 'active' : '',
            selectedLayout === layout ? 'active' : '',
          ]"
          @click="handleSelectLayout(layout.apparence)"
        >
          <div class="nav-small">
            <img
              src="../../../assets/logo-full.png"
              alt=""
              height="15"
              class="mt-2 ml-1"
            />
          </div>
          <div class="side-small"></div>
          <div class="d-flex">
            <div class="content-small">
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
        </b-card>
      </div>
    </section>
  </div>
</template>
<script>
import layoutProvider from "@/services/apparence/layout";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isMobile: false,
      selectedLayout: null,
      layoutsMenuLeft: [],
      layoutsMenuTop: [
        {
          label: "Em caixa com menu superior",
          apparence: "boxed-top",
        },
        {
          label: "Estendido com menu superior",
          apparence: "extend-top",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["is_mobile", "is_dark"]),
  },
  methods: {
    ...mapActions(["set_menu_layout"]),
    handleSelectLayout(layout) {
      const splited = layout.split("-");
      if (splited.includes("top")) {
        this.set_menu_layout("top");
      } else {
        this.set_menu_layout("left");
      }
      this.selectedLayout = layout;
      layoutProvider(layout);
    },
    setIsMobile(isMobile) {
      if (isMobile && this.layoutsMenuLeft.length > 0) {
        this.layoutsMenuLeft.shift();
        this.layoutsMenuTop.shift();
        this.layoutsMenuLeft[0].label = "Com o menu na parte inferior";
        this.layoutsMenuTop[0].label = "Com o menu na parte superior";
      } else {
        this.layoutsMenuLeft = [
          {
            label: "Em caixa",
            apparence: "boxed",
          },
          {
            label: "Estendido",
            apparence: "extend",
          },
        ];
        this.layoutsMenuTop = [
          {
            label: "Em caixa com menu superior",
            apparence: "boxed-top",
          },
          {
            label: "Estendido com menu superior",
            apparence: "extend-top",
          },
        ];
      }
    },
  },
  mounted() {
    this.selectedLayout = localStorage.getItem("layout");
    this.setIsMobile(this.is_mobile);
  },
  watch: {
    is_mobile: {
      handler(isMobile) {
        this.setIsMobile(isMobile);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.apparence-small {
  background: $bg !important;
  &.layout {
    .d-md-flex {
      height: 100%;
    }
    &.boxed {
      .content-small {
        padding: 12px 62px;
      }
    }
    &.extend {
      .content-small {
        padding: 12px 8px;
      }
      @media (max-width: $break-md) {
        .side-small {
          height: 30px;
          width: 100%;
          background: var(--bg-2);
          position: absolute;
          bottom: 0;
        }
      }
    }
    &.dark {
      .nav-small {
        background: #1f1f1f !important;
      }
    }
  }
}
.menu-top {
  .apparence-small {
    .side-small {
      width: 100%;
      height: 30px;
      background: $bg-2;
    }
    .content-small {
      margin-top: 16px;
    }
    &.layout {
      &.boxed-top {
        .content-small {
          padding: 12px 62px;
        }
      }
      &.extend-top {
        .content-small {
          padding: 12px 8px;
        }
      }
    }
  }
}
</style>
